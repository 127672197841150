import { axiosPublic } from "../http-utils/axios-public";
import Session from "../session/Session";

export default class AuthApi {
  public static async doLogin(
    login: string,
    senha: string
  ): Promise<Session | undefined> {
    try {
      const response = await axiosPublic.post("/do-login", {
        login: login,
        password: senha,
      });
      const data = response.data;
      return new Session(data.type, data.token, data.refreshToken);
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  public static async refreshToken(
    refreshToken: string
  ): Promise<Session | undefined> {
    try {
      let config = { headers: { Authorization: 'Bearer ' + refreshToken } };
      const response = await axiosPublic.post(
        "/refresh-token",
        {},
        config
      );
      const data = response.data;
      return new Session(data.type, data.token, data.refreshToken);
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

import axios from "axios";

const axiosBaseConfig = {
  baseURL: process.env.REACT_APP_AUTH_BASE_API,
  timeout: 3000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

const axiosPublic = axios.create(axiosBaseConfig);
export { axiosBaseConfig, axiosPublic };

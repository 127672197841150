export default class Session {
  private type: string = "";
  private token: string = "";
  private refreshToken: string = "";

  public constructor(
    type: string = "",
    token: string = "",
    refreshToken: string = ""
  ) {
    this.type = type;
    this.token = token;
    this.refreshToken = refreshToken;
  }

  public getType(): string {
    return this.type;
  }

  public getToken(): string {
    return this.token;
  }

  public getRefreshToken(): string {
    return this.refreshToken;
  }
}

import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { sessionService } from "../../session";
import SessaoExpiradaError from "../../session/SessaoExpirada";
import { softwareService } from "../../software";
import Software from "../../software/Software";
import ItemMenu from "./item/ItemMenu";


interface MenuProps {
  onLogout: () => void;
}

const Menu = (props: MenuProps) => {
  const [softwares, setSoftwares] = useState(new Array<Software>());
  const [loaded, setLoaded] = useState(false);

  const logout = () => {
    sessionService.logout();
    props.onLogout();
  };

  const getSoftwares = async () => {
    const session = sessionService.getSession();
    if (session === undefined) {
      logout();
      return;
    }
    
    try{
      setSoftwares(await softwareService.getSoftwares(sessionService.getIdUsuario()));
    } catch(error){
      console.log(error);
      if(error instanceof SessaoExpiradaError){
        logout();
      }
    }
  };

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      getSoftwares();
    }
  },[loaded, getSoftwares]);

  const items = softwares.map((s) => (
                  <ItemMenu key={s.getId()} software={s} logout={logout}/>
                )); 

  return (
    <>
      <div className="card flex justify-content-center m-1">
        <Button label="Sair" severity="danger" onClick={logout} />
      </div>
      <div className="card flex justify-content-center m-0">
        <ul>
          {items}
        </ul>
      </div>
    </>
  );
};

export default Menu;

import "primereact/resources/themes/lara-light-cyan/theme.css";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
//import "primereact/resources/themes/bootstrap4-dark-blue/theme.css";
import { PrimeReactProvider } from "primereact/api";
import App from "./App";
//import reportWebVitals from "./reportWebVitals";
import "/node_modules/primeflex/primeflex.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <PrimeReactProvider>
      <App />
    </PrimeReactProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

export default class Software {
  private id: number = 0;
  private name: string = "";
  private description: string = "";
  private url: string = "";
  private active: boolean = false;
  private showInMenu: boolean = false;

  public constructor(
    id: number,
    name: string,
    description: string,
    url: string,
    active: boolean,
    showInMenu: boolean
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.url = url;
    this.active = active;
    this.showInMenu = showInMenu;
  }

  public static fromJson(json: string): Software {
    const obj = JSON.parse(json);
    return new Software(
      obj.id,
      obj.name,
      obj.description,
      obj.url,
      obj.active,
      obj.showInMenu
    );
  }

  public getId() {
    return this.id;
  }
  public getName() {
    return this.name;
  }
  public getDescription() {
    return this.description;
  }
  public getUrl() {
    return this.url;
  }
  public getActive() {
    return this.active;
  }
  public getShowInMenu() {
    return this.showInMenu;
  }
}

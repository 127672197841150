import SoftwareApi from "./SoftwareApi";

export default class SoftwareService {
    constructor(private readonly softwareApi: SoftwareApi){
        
    }

    async getSoftwares(userId: string){
        return await this.softwareApi.getSoftwares(userId);
    }
}
import axios, { Axios, AxiosError } from "axios";
import SessionService from "./SessionService";
import { axiosBaseConfig } from "../http-utils/axios-public";
import SessaoExpiradaError from "./SessaoExpirada";

const axiosSessionBaseConfig = {
  baseURL: process.env.REACT_APP_AUTHSERVICE_ADMIN_API,
};

class AxiosSession {
  private sessionService: SessionService;

  constructor(sessionService: SessionService) {
    this.sessionService = sessionService;
  }

  public getAxios(): Axios {
    return this.createSessionAxios();
  }

  private createSessionAxios(): Axios {
    const sessionService = this.sessionService;

    const sessionAxios = axios.create(Object.assign({}, axiosBaseConfig, axiosSessionBaseConfig));
    sessionAxios.interceptors.request.use((config) => {
      config.headers = Object.assign({}, config.headers, {
        Authorization: sessionService.getSession()?.getType() +
         " " +sessionService.getSession()?.getToken(),
      });
      return config;
    });

    sessionAxios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const ae = error as AxiosError;

        const refreshToken = sessionService.getSession()?.getRefreshToken();
        if (
          refreshToken !== undefined &&
          ae.response?.status === 401 &&
          ae.response.config.headers["retry"] === undefined
        ) {
          if (await sessionService.atualizaSessao()) {
            const config = ae.response.config;
            config.headers = Object.assign({}, config.headers, {
              retry: false,
            });
            return sessionAxios.request(config);
          }
          throw new SessaoExpiradaError();
        }

        return Promise.reject(error);
      }
    );

    return sessionAxios;
  }
}

export default AxiosSession;

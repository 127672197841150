import { Axios } from "axios";
import AxiosSession from "../session/AxiosSession";
import Software from "./Software";

export default class SoftwareApi {
  private axios: Axios;

  public constructor(axiosSession: AxiosSession) {
    this.axios = axiosSession.getAxios();
  }

  public async getSoftwares(userId: string): Promise<Array<Software>> {
    const response = await this.axios.get(`/api/user-software/user/${userId}`);
    const data = response.data;
    const softwares = data.content;

    return softwares.map(
      (us: any) =>
        new Software(
          us.software.id,
          us.software.name,
          us.software.description,
          us.software.url,
          us.software.active,
          us.software.showInMenu
        )
    );
  }
}

import { useEffect, useState } from "react";
import "./App.css";
import {sessionService} from "./session";
import Login from "./view/login/Login";
import Menu from "./view/menu/Menu";

function App() {
  const [logado, setLogado] = useState(false);

  const updateSession = () => {
    if (sessionService.isLogado()) {
      setLogado(true);
    }
  };

  const logout = () => {
    sessionService.logout();
    setLogado(false);
  };

  useEffect(() => {
    updateSession();
  });

  return (
    <div className="App">
      {logado ? <Menu onLogout={logout} /> : <Login onLogin={updateSession} />}
    </div>
  );
}

export default App;

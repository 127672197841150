import AuthApi from "../api/AuthApi";
import Session from "./Session";
import * as jose from 'jose'

export default class SessionService {
  private static readonly sessionKey: string = "session";

  public save(session: Session): void {
    sessionStorage.setItem(SessionService.sessionKey, JSON.stringify(session));
  }

  public getSession(): Session | undefined {
    const sessionJson = sessionStorage.getItem(SessionService.sessionKey);
    if (sessionJson === null || sessionJson === "undefined") {
      return undefined;
    }

    const sessionObj = JSON.parse(sessionJson);

    if (!sessionObj) {
      return undefined;
    }

    return new Session(
      sessionObj.type,
      sessionObj.token,
      sessionObj.refreshToken
    );
  }

  public logout(): void {
    sessionStorage.removeItem(SessionService.sessionKey);
  }

  public isLogado(): boolean {
    const session = this.getSession();

    if (session === undefined) {
      return false;
    }

    return session.getToken() !== "";
  }

  public async atualizaSessao(): Promise<boolean> {
    const refreshToken = this.getSession()?.getRefreshToken();
    if (refreshToken === undefined) {
      return false;
    }

    const newSession = await AuthApi.refreshToken(refreshToken);
    if (newSession === undefined) {
      return false;
    }

    this.save(newSession);
    return true;
  }

  private getPayLoad(): jose.JWTPayload{
    const token = this.getSession()?.getToken();
    if(token === undefined){
      throw new Error("Sessão não iniciada.");
    }

    return jose.decodeJwt(token);
  }

  public getIdUsuario(): string{

    const payload = this.getPayLoad();

    return payload.sub || "";
  }
}
